<template>
  <div class="radio-btns" :class="classNameError" :id="id">
    <div class="radio-btns__list">
      <div
        class="radio-btns__item"
        v-for="(item, index) in radioBtnsItems"
        :key="index"
        :class="className"
      >
        <input
          :id="`radio${index}_${uniqComponentId}`"
          type="radio"
          v-model="value"
          :value="Number(item.value)"
          v-bind="$attrs"
          :readonly="readonly"
        />
        <label :for="`radio${index}_${uniqComponentId}`">{{ item.key }}</label>
        <div class="radio-btns__text">
          {{ item.text }}
        </div>
      </div>
    </div>
    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
export default {
  name: "RadioBtns",
  data() {
    return {
      uniqComponentId: uniqueString(),
    };
  },
  props: {
    modelValue: [Boolean, Number],
    university: Boolean,
    disabled: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
    text: {
      type: Array,
      default: () => [],
    },
    errorArray: Array,
    error: Boolean,
    id: [String, Number],
  },
  computed: {
    className() {
      return {
        "radio-btns__item--university": this.university,
      };
    },
    classNameError() {
      return {
        "radio-btns--error": this.error,
        "radio-btns--disabled": this.disabled,
      };
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    radioBtnsItems() {
      return this.items.map((item, index) => {
        return {
          key: this.keys[index] ?? item,
          value: item,
          text: this.text[index],
        };
      });
    },
  },
};
</script>

<style lang="scss">
.radio-btns {
  &--error {
    margin-bottom: 15px;
    position: relative;
    .form__error {
      color: var(--red);
      position: absolute;
      top: 100%;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__item {
    label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      line-height: 25px;
      color: var(--black2);
      &::before {
        content: "";
        display: flex;
        margin-right: 10px;
        width: 22px;
        height: 22px;
        border: 3px solid var(--btn-blue-color1);
        border-radius: 50%;
        flex-shrink: 0;
      }
      &::after {
        content: "";
        display: flex;
        margin-right: 10px;
        width: 12px;
        height: 12px;
        background-color: var(--btn-blue-color1);
        border-radius: 50%;
        position: absolute;
        left: 5px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-in-out;
        flex-shrink: 0;
      }
    }
    input {
      display: none;
      &:checked + label::after {
        visibility: visible;
        opacity: 1;
      }
      margin: 0;
      padding: 0;
    }
    &--university {
      label {
        font-weight: 600;
      }
    }
  }
  &__text {
    color: var(--gray);
    font-size: 16px;
    line-height: 23px;
    margin-top: 10px;
    max-width: 700px;
  }
  &--disabled {
    pointer-events: none;
    .radio-btns__item {
      label {
        &::before {
          border: 3px solid var(--btn-gray-color1);
        }
        &::after {
          background-color: var(--btn-gray-color1);
        }
      }
    }
  }
  @include adaptive(phone) {
    &__item {
      label {
        font-size: 14px;
        line-height: 16px;
      }
    }
    &__text {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
