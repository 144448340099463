<template>
  <div class="container">
    <div class="lk-requests">
      <div
        class="lk-requests__title"
        v-if="user?.info_university?.name && currentStatements?.length === 0"
      >
        <p>
          Обратите внимание! <br />
          Вы подаёте заявку, обучаясь в
          <span>{{ user?.info_university?.name }}</span>
          <br />
          После подачи заявки, сменить учебное заведение будет нельзя.
        </p>
      </div>
      <div class="lk-requests__content" v-if="info && info.length > 0">
        <div class="lk-requests__shifts">
          <h2>Выберите смену</h2>
          <div class="shifts__list" v-if="formattedInfo">
            <div
              class="shifts-item shifts-item--blue"
              v-for="(item, index) in formattedInfo"
              :key="index"
              :class="[
                item.class,
                {
                  'shifts-item--active':
                    $store.state.currentShiftId === item.id,
                },
                {
                  'shifts-item--disabled':
                    !item.is_access_new_statement || !!item.class,
                },
                {
                  'shifts-item--green':
                    item.status !== undefined && item.status === 50,
                },
                {
                  'shifts-item--yellow':
                    (item.status !== undefined && item.status === 0) ||
                    (item.status !== undefined && item.status === 35) ||
                    (item.status !== undefined && item.status === 10) ||
                    (item.status !== undefined && item.status === 38) ||
                    (item.status !== undefined && item.status === 37),
                },
              ]"
            >
              <div class="shifts-item__header">
                <span>{{ item.title }}</span>
                <p>{{ item.time_start }} - {{ item.time_end }}</p>
              </div>
              <div class="shifts-item__age">16 - 28 лет</div>
              <div class="shifts-item__desc">
                {{ item.text }}
              </div>
              <div class="shifts-item__reg">
                Регистрация открыта до {{ item.ended_bid }}
              </div>

              <p class="shifts-item__status" v-if="item.status === 35">
                На доработке
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 10">
                На рассмотрении
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 37">
                Ожидает проверки
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 38">
                В резерве
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 50">
                Заявка одобрена
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 28">
                Неверные контактные данные
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 27">
                Не отвечает
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 40">
                Отказано
                <span @click="test(item.id, 'comment')"> ( Комментарий ) </span>
              </p>
              <p class="shifts-item__status" v-else-if="item.status === 45">
                Отказался
                <!--                <span @click="test(item.id, 'comment')"> ( Комментарий ) </span>-->
              </p>
              <p
                class="shifts-item__status"
                v-else-if="item.status !== undefined"
              >
                Заявка подана
              </p>
              <div class="shifts-item__bot">
                <!--                <Button-->
                <!--                  white-->
                <!--                  @click="-->
                <!--                    setShift(-->
                <!--                      item.id,-->
                <!--                      'requestData',-->
                <!--                      item.is_access_new_statement-->
                <!--                    )-->
                <!--                  "-->
                <!--                  :disabled="!item.is_access_new_statement || !!item.class"-->
                <!--                >-->
                <!--                  <span v-if="!item.is_access_new_statement || !!item.class">-->
                <!--                    Подача невозможна-->
                <!--                  </span>-->

                <!--                  <span-->
                <!--                    v-else-if="-->
                <!--                      !item.status?.toString() ||-->
                <!--                      (item.is_access_new_statement && item.status === 45)-->
                <!--                    "-->
                <!--                  >-->
                <!--                    {{-->
                <!--                      isShiftSet && item.id === $store.state.currentShiftId-->
                <!--                        ? "Подаем заявку"-->
                <!--                        : "ПОДАТЬ ЗАЯВКУ"-->
                <!--                    }}-->
                <!--                  </span>-->

                <!--                  <span-->
                <!--                    v-else-if="-->
                <!--                      item.is_access_new_statement &&-->
                <!--                      item.status?.toString() &&-->
                <!--                      (item.status === 35 ||-->
                <!--                        item.status === 0 ||-->
                <!--                        item.status === 27 ||-->
                <!--                        item.status === 28 ||-->
                <!--                        item.status === 35)-->
                <!--                    "-->
                <!--                  >-->
                <!--                    Редактировать-->
                <!--                  </span>-->

                <!--                  <span v-else>Посмотреть</span>-->
                <!--                </Button>-->
                <a
                  href="https://lk.ligastudentov.com/"
                  class="btn btn--white"
                  target="_blank"
                >
                  ПОДАТЬ ЗАЯВКУ
                </a>
                <div class="shifts-item__hint">
                  <router-link
                    :to="{ name: 'Shift', params: { slug: item.slug } }"
                    target="_blank"
                  >
                    <Icon :type="'View-On'" />
                  </router-link>
                  <span>Подробнее</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lk-requests__shifts-content" ref="comment">
          <div
            class="lk-requests__comment"
            v-if="
              (currentData?.status === 40 || currentData?.status === 45) &&
              currentData.comment_manager
            "
          >
            <div class="lk-requests__comment-title">
              Комментарий к статусу "{{ currentData.status_text }}":
            </div>
            <div class="lk-requests__comment-text">
              {{ currentData.comment_manager }}
            </div>
          </div>
        </div>
        <div
          class="lk-requests__shifts-content"
          ref="requestData"
          v-if="!isLoadingData"
        >
          <div
            class="lk-requests__comment"
            v-if="currentData?.status === 35 && currentData.comment_manager"
          >
            <div class="lk-requests__comment-title">
              Комментарий к статусу "На доработке":
            </div>
            <div class="lk-requests__comment-text">
              {{ currentData.comment_manager }}
            </div>
          </div>
          <div class="lk-requests__content-info" v-if="isShiftSet">
            <div class="lk-requests__priorities">
              <p>Образовательный трек*</p>
              <Dropdown
                class="formData_first_direction_id"
                :items="formattedInfoTracks[0].id"
                :keys="formattedInfoTracks[0].title"
                v-model="formData.first_direction_id"
                @current-value="
                  (key) => {
                    this.first_direction_name = key.key;
                    this.formData.second_direction_id = null;
                    this.second_direction_name = null;
                    this.formData.third_direction_id = null;
                    this.third_direction_name = null;
                  }
                "
                :start-value="'Приоритет 1'"
                :error-array="v$.formData.first_direction_id.$errors"
                @blur="v$.formData.first_direction_id.$touch"
                :error="
                  v$.formData.first_direction_id.$errors !== 0 &&
                  v$.formData.first_direction_id.$errors[0]
                "
                :disabled="isDisabled"
              />
              <Dropdown
                class="formData_second_direction_id"
                :disabled="isDisabled || !formData.first_direction_id"
                :items="formattedInfoTracksSecond[0]?.id"
                :keys="formattedInfoTracksSecond[0]?.title"
                v-model="formData.second_direction_id"
                @current-value="
                  (key) => {
                    this.second_direction_name = key.key;
                    this.formData.third_direction_id = null;
                    this.third_direction_name = null;
                  }
                "
                :start-value="'Приоритет 2'"
                :error-array="v$.formData.second_direction_id.$errors"
                @blur="v$.formData.second_direction_id.$touch"
                :error="
                  v$.formData.second_direction_id.$errors !== 0 &&
                  v$.formData.second_direction_id.$errors[0]
                "
              />
              <Dropdown
                class="formData_third_direction_id"
                :disabled="isDisabled || !formData.second_direction_id"
                :items="formattedInfoTracksThird[0]?.id"
                :keys="formattedInfoTracksThird[0]?.title"
                v-model="formData.third_direction_id"
                @current-value="
                  (key) => {
                    this.third_direction_name = key.key;
                  }
                "
                :start-value="'Приоритет 3'"
                :error-array="v$.formData.third_direction_id.$errors"
                @blur="v$.formData.third_direction_id.$touch"
                :error="
                  v$.formData.third_direction_id.$errors !== 0 &&
                  v$.formData.third_direction_id.$errors[0]
                "
              />
            </div>
            <div class="lk-requests__priorities">
              <p>Курс обучения*</p>
              <Dropdown
                class="formData_course_level"
                :items="
                  listCourses.map((item) => {
                    return +item.key;
                  })
                "
                :keys="
                  listCourses.map((item) => {
                    return item.value;
                  })
                "
                v-model="formData.course_level"
                :start-value="'Курс'"
                :error-array="v$.formData.course_level.$errors"
                @blur="v$.formData.course_level.$touch"
                :error="
                  v$.formData.course_level.$errors !== 0 &&
                  v$.formData.course_level.$errors[0]
                "
                :disabled="isDisabled"
              />
            </div>
            <div class="lk-requests__priorities">
              <p>Размер одежды*</p>
              <Dropdown
                class="formData_clothing_size"
                :items="Object.keys(listSizes)"
                :keys="Object.values(listSizes)"
                v-model="formData.clothing_size"
                :start-value="'Размер'"
                :error-array="v$.formData.clothing_size.$errors"
                @blur="v$.formData.clothing_size.$touch"
                :error="
                  v$.formData.clothing_size.$errors !== 0 &&
                  v$.formData.clothing_size.$errors[0]
                "
                :disabled="isDisabled"
              />
            </div>
            <div class="lk-requests__container">
              <div class="lk-requests__answer">
                <p>Какое сообщество вы представляете?*</p>
                <RadioBtns
                  class="formData_community_represent_id"
                  v-model="formData.community_represent_id"
                  :items="Object.keys(listRepresents)"
                  :keys="Object.values(listRepresents)"
                  :error-array="v$.formData.community_represent_id.$errors"
                  @blur="v$.formData.community_represent_id.$touch"
                  :error="
                    v$.formData.community_represent_id.$errors !== 0 &&
                    v$.formData.community_represent_id.$errors[0]
                  "
                  @update:modelValue="communityRepresentIdChanged"
                  :disabled="isDisabled"
                />
                <Input
                  class="formData_community_represent_other"
                  :placeholder="'Другое'"
                  v-model="formData.community_represent_other"
                  v-if="formData.community_represent_id === 99"
                  :error-array="v$.formData.community_represent_other.$errors"
                  @blur="v$.formData.community_represent_other.$touch"
                  :error="
                    v$.formData.community_represent_other.$errors !== 0 &&
                    v$.formData.community_represent_other.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div
                class="lk-requests__answer"
                v-if="formData.community_represent_id !== 0"
              >
                <p>Название сообщества*</p>
                <TextAreaBlock
                  class="formData_community_represent_name"
                  :placeholder="'Введите название сообщества, в котором Вы состоите'"
                  v-model="formData.community_represent_name"
                  :error-array="v$.formData.community_represent_name.$errors"
                  @blur="v$.formData.community_represent_name.$touch"
                  :error="
                    v$.formData.community_represent_name.$errors !== 0 &&
                    v$.formData.community_represent_name.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__answer">
                <p>Ваша позиция в этой организации*</p>
                <RadioBtns
                  class="formData_position_organization_id"
                  v-model="formData.position_organization_id"
                  :items="Object.keys(listOrganization)"
                  :keys="Object.values(listOrganization)"
                  :error-array="v$.formData.position_organization_id.$errors"
                  @blur="v$.formData.position_organization_id.$touch"
                  :error="
                    v$.formData.position_organization_id.$errors !== 0 &&
                    v$.formData.position_organization_id.$errors[0]
                  "
                  @update:modelValue="positionOrganizationChanged"
                  :disabled="isDisabled"
                />
                <Input
                  class="formData_position_organization_other"
                  :placeholder="'Другое'"
                  v-model="formData.position_organization_other"
                  v-if="formData.position_organization_id === 99"
                  :error-array="v$.formData.position_organization_other.$errors"
                  @blur="v$.formData.position_organization_other.$touch"
                  :error="
                    v$.formData.position_organization_other.$errors !== 0 &&
                    v$.formData.position_organization_other.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__answer">
                <div class="lk-requests__answer-head">
                  <p>Что для тебя значит студенчество?*</p>
                  <Icon
                    type="Help"
                    data-tooltip="Ответ на развёрнутый вопрос должен быть полным, содержать не менее 4 и не более 6 предложений."
                  />
                </div>
                <TextAreaBlock
                  class="formData_what_does_student_mean_you"
                  v-model="formData.what_does_student_mean_you"
                  :placeholder="'Студенчество для меня...'"
                  :error-array="v$.formData.what_does_student_mean_you.$errors"
                  @blur="v$.formData.what_does_student_mean_you.$touch"
                  :error="
                    v$.formData.what_does_student_mean_you.$errors !== 0 &&
                    v$.formData.what_does_student_mean_you.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__answer">
                <div class="lk-requests__answer-head">
                  <p>Твоя цель поездки на «ЛИГА ФОРУМ»*</p>
                  <Icon
                    type="Help"
                    data-tooltip="Ответ на развёрнутый вопрос должен быть полным, содержать не менее 4 и не более 6 предложений."
                  />
                </div>

                <TextAreaBlock
                  class="formData_purpose_travel"
                  v-model="formData.purpose_travel"
                  :placeholder="'Я хочу поехать на «ЛИГА ФОРУМ», потому что'"
                  :error-array="v$.formData.purpose_travel.$errors"
                  @blur="v$.formData.purpose_travel.$touch"
                  :error="
                    v$.formData.purpose_travel.$errors !== 0 &&
                    v$.formData.purpose_travel.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__answer">
                <div class="lk-requests__answer-head">
                  <p>Твои главные достижение за последние 3 года*</p>
                  <Icon
                    type="Help"
                    data-tooltip="Ответ на развёрнутый вопрос должен быть полным, содержать не менее 4 и не более 6 предложений."
                  />
                </div>

                <TextAreaBlock
                  class="formData_your_main_achievements"
                  v-model="formData.your_main_achievements"
                  :placeholder="'Достижение 1. Я...'"
                  :error-array="v$.formData.your_main_achievements.$errors"
                  @blur="v$.formData.your_main_achievements.$touch"
                  :error="
                    v$.formData.your_main_achievements.$errors !== 0 &&
                    v$.formData.your_main_achievements.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__answer">
                <p>Особенности здоровья</p>
                <TextAreaBlock
                  v-model="formData.health_features"
                  :placeholder="'Хронические заболевания, аллергия'"
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__priorities">
                <p>Предпочтение в питании*</p>
                <Tabs
                  class="formData_food_preference_id"
                  v-model="formData.food_preference_id"
                  :items="Object.keys(listPreference)"
                  :keys="Object.values(listPreference)"
                  :error-array="v$.formData.food_preference_id.$errors"
                  @blur="v$.formData.food_preference_id.$touch"
                  :error="
                    v$.formData.food_preference_id.$errors !== 0 &&
                    v$.formData.food_preference_id.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
              <div class="lk-requests__answer">
                <p>Как вы узнали о форуме?*</p>
                <RadioBtns
                  class="formData_hear_about_forum_id"
                  v-model="formData.hear_about_forum_id"
                  :items="
                    listAboutForum.map((item) => {
                      return item.key;
                    })
                  "
                  :keys="
                    listAboutForum.map((item) => {
                      return item.value;
                    })
                  "
                  :error-array="v$.formData.hear_about_forum_id.$errors"
                  @blur="v$.formData.hear_about_forum_id.$touch"
                  :error="
                    v$.formData.hear_about_forum_id.$errors !== 0 &&
                    v$.formData.hear_about_forum_id.$errors[0]
                  "
                  @update:modelValue="hearAboutForumChanged"
                  :disabled="isDisabled"
                />
                <Input
                  class="formData_hear_about_forum_other"
                  v-model="formData.hear_about_forum_other"
                  v-if="formData.hear_about_forum_id === 99"
                  :placeholder="'Другое'"
                  :error-array="v$.formData.hear_about_forum_other.$errors"
                  @blur="v$.formData.hear_about_forum_other.$touch"
                  :error="
                    v$.formData.hear_about_forum_other.$errors !== 0 &&
                    v$.formData.hear_about_forum_other.$errors[0]
                  "
                  :disabled="isDisabled"
                />
                <Input
                  class="formData_hear_about_forum_friend"
                  v-model="formData.hear_about_forum_friend"
                  v-if="formData.hear_about_forum_id === 77"
                  :placeholder="'ФИО друга'"
                  :error-array="v$.formData.hear_about_forum_friend.$errors"
                  @blur="v$.formData.hear_about_forum_friend.$touch"
                  :error="
                    v$.formData.hear_about_forum_friend.$errors !== 0 &&
                    v$.formData.hear_about_forum_friend.$errors[0]
                  "
                  :disabled="isDisabled"
                />
              </div>
            </div>
            <div class="lk-requests__answer">
              <h2>Направления команды</h2>
              <div class="teams__list">
                <div
                  class="teams-item"
                  v-for="(item, index) in teamsInfo"
                  :key="index"
                  @click="openPopup(item, item.info_photo_gallery)"
                >
                  <div class="teams-item__img">
                    <img :src="item.logo" :alt="item.title" />
                  </div>
                  <div class="teams-item__title">{{ item.title }}</div>
                </div>
              </div>
            </div>
            <div class="lk-requests__priorities">
              <p>Приоритеты по командам*</p>
              <Dropdown
                class="formData_first_team_id"
                :items="teamsPriorities.map((item) => item.id)"
                :keys="teamsPriorities.map((item) => item.title)"
                v-model="formData.first_team_id"
                :start-value="'Приоритет 1'"
                :error-array="v$.formData.first_team_id.$errors"
                @blur="v$.formData.first_team_id.$touch"
                :error="
                  v$.formData.first_team_id.$errors !== 0 &&
                  v$.formData.first_team_id.$errors[0]
                "
                @current-value="
                  (key) => {
                    this.first_team_name = key.key;
                    this.formData.second_team_id = null;
                    this.formData.third_team_id = null;
                  }
                "
                :disabled="isDisabled"
              />
              <Dropdown
                class="formData_second_team_id"
                :disabled="isDisabled || !formData.first_team_id"
                :items="
                  teamsPriorities
                    .filter((team) => {
                      return team.id !== formData.first_team_id;
                    })
                    .map((item) => item.id)
                "
                :keys="
                  teamsPriorities
                    .filter((team) => {
                      return team.title !== first_team_name;
                    })
                    .map((item) => item.title)
                "
                v-model="formData.second_team_id"
                :start-value="'Приоритет 2'"
                :error-array="v$.formData.second_team_id.$errors"
                @blur="v$.formData.second_team_id.$touch"
                :error="
                  v$.formData.second_team_id.$errors !== 0 &&
                  v$.formData.second_team_id.$errors[0]
                "
                @current-value="
                  (key) => {
                    this.second_team_name = key.key;
                    this.formData.third_team_id = null;
                  }
                "
              />
              <Dropdown
                class="formData_third_team_id"
                :disabled="isDisabled || !formData.second_team_id"
                :items="
                  teamsPriorities
                    .filter((team) => {
                      return team.id !== formData.first_team_id;
                    })
                    .filter((team) => {
                      return team.id !== formData.second_team_id;
                    })
                    .map((item) => item.id)
                "
                :keys="
                  teamsPriorities
                    .filter((team) => {
                      return team.title !== first_team_name;
                    })
                    .filter((team) => {
                      return team.title !== second_team_name;
                    })
                    .map((item) => item.title)
                "
                v-model="formData.third_team_id"
                :start-value="'Приоритет 3'"
                :error-array="v$.formData.third_team_id.$errors"
                @blur="v$.formData.third_team_id.$touch"
                :error="
                  v$.formData.third_team_id.$errors !== 0 &&
                  v$.formData.third_team_id.$errors[0]
                "
              />
            </div>
            <div class="lk-requests__submit">
              <div v-if="errors?.length !== 0" class="lk-requests__errors">
                <span v-for="(item, index) in errors" :key="index">
                  {{ item.error_descr }}
                </span>
              </div>
              <Button
                red
                @click="handleFormSubmit"
                :loading="isLoading"
                :disabled="formIncorrect || !formChanged"
                v-if="!isDisabled"
              >
                {{
                  this.currentData !== undefined &&
                  this.currentData.status !== 45
                    ? "Редактировать"
                    : "Отправить заявку"
                }}
              </Button>
              <div class="data-saved" v-if="dataSuccess">
                Заявка успешно подана
              </div>
              <div class="data-saved" v-else-if="dataUpdated">
                Данные успешно обновлены
              </div>
              <a
                @click="goToAnchor(firstError)"
                v-if="firstError"
                class="to-error"
              >
                Обратите внимние на заполнение полей.
              </a>
            </div>
          </div>
        </div>
        <Preloader v-else />
      </div>
      <Preloader v-else />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { createRequest } from "@/api/requestBuilder";
import Button from "@/components/Blocks/Button";
import Icon from "@/components/Blocks/Icon";
import Dropdown from "@/components/Blocks/Dropdown";
import RadioBtns from "@/components/Blocks/RadioBtns";
import Input from "@/components/Blocks/Input";
import TextAreaBlock from "@/components/Blocks/TextAreaBlock";
import Tabs from "@/components/Blocks/Tabs";
import useVuelidate from "@vuelidate/core";
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
const { mapGetters, mapState } = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
import requestConfigs from "@/api/requestConfigs";
import Preloader from "@/components/Blocks/Preloader";
import store from "@/store";

export default {
  name: "LkRequest",
  components: {
    Preloader,
    Tabs,
    TextAreaBlock,
    Input,
    RadioBtns,
    Dropdown,
    Icon,
    Button,
  },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      currentStatementId: null,
      isLoadingData: false,
      dataSuccess: false,
      dataUpdated: false,
      formChanged: false,
      timeout: null,
      payload: {},
      currentData: {},
      teamsInfo: [],
      isLoading: false,
      radioIndex: 0,
      tabsIndex: 0,
      allParam: {},
      formattedInfoTracks: [],
      formattedInfoTracksSecond: [],
      formattedInfoTracksThird: [],
      representsText: "",
      isShiftSet: false,
      formIncorrect: false,
      first_direction_name: null,
      second_direction_name: null,
      third_direction_name: null,
      first_team_name: null,
      second_team_name: null,
      third_team_name: null,
      errors: [],
      formData: {
        is_valid_shift: true,
        shift_id: null,
        first_direction_id: null,
        second_direction_id: null,
        third_direction_id: null,
        course_level: null,
        clothing_size: null,
        community_represent_id: null,
        community_represent_other: null,
        community_represent_name: null,
        position_organization_other: null,
        position_organization_id: null,
        what_does_student_mean_you: null,
        purpose_travel: null,
        your_main_achievements: null,
        hear_about_forum_id: null,
        hear_about_forum_other: null,
        hear_about_forum_friend: null,
        health_features: null,
        food_preference_id: null,
        first_team_id: null,
        second_team_id: null,
        third_team_id: null,
      },
    };
  },
  validations() {
    return {
      formData: {
        first_direction_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        second_direction_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        third_direction_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        course_level: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        clothing_size: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        community_represent_id: {
          required:
            this.formData.community_represent_id !== 0
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
        },
        community_represent_other: {
          required:
            this.formData.community_represent_id === 99
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 100",
            maxLength(100)
          ),
        },
        community_represent_name: {
          required:
            this.formData.community_represent_id !== 0
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 100",
            maxLength(100)
          ),
        },
        position_organization_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        position_organization_other: {
          required:
            this.formData.position_organization_id === 99
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 100",
            maxLength(100)
          ),
        },
        what_does_student_mean_you: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 2000",
            maxLength(2000)
          ),
        },
        purpose_travel: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 2000",
            maxLength(2000)
          ),
        },
        your_main_achievements: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 2000",
            maxLength(2000)
          ),
        },
        food_preference_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        hear_about_forum_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        hear_about_forum_other: {
          required:
            this.formData.hear_about_forum_id === 99
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 100",
            maxLength(100)
          ),
        },
        hear_about_forum_friend: {
          required:
            this.formData.hear_about_forum_id === 77
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
          minLength: helpers.withMessage(
            "Количество символов не менее 2",
            minLength(2)
          ),
          maxLength: helpers.withMessage(
            "Количество символов не более 50",
            maxLength(50)
          ),
        },
        first_team_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        second_team_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        third_team_id: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
      },
    };
  },
  watch: {
    "$store.state.currentShiftId"() {
      this.formData.shift_id = this.$store.state.currentShiftId;
      this.formattedInfoTracks = this.infoTracks;
    },
    formData: {
      handler: function () {
        this.formChanged = true;
        this.$store.commit("setDataSaved", false);
        this.$store.state.dataSaved = false;
      },
      deep: true,
    },
    "v$.$errors.length"() {
      this.v$.$errors.length === 0 ? (this.formIncorrect = false) : true;
    },
    "formData.first_direction_id"() {
      this.formattedInfoTracksSecond = this.formattedInfoTracks.map((track) => {
        return {
          id: track.id.filter((item) => {
            return item !== this.formData.first_direction_id;
          }),
          title: track.title.filter((item) => {
            return item !== this.first_direction_name;
          }),
        };
      });
    },
    "formData.second_direction_id"() {
      this.formattedInfoTracks = this.infoTracks.map((track) => {
        return {
          id: track.id.filter((item) => {
            return item !== this.formData.second_direction_id;
          }),
          title: track.title.filter((item) => {
            return item !== this.second_direction_name;
          }),
        };
      });
      this.formattedInfoTracksThird = this.formattedInfoTracksSecond.map(
        (track) => {
          return {
            id: track.id.filter((item) => {
              return item !== this.formData.second_direction_id;
            }),
            title: track.title.filter((item) => {
              return item !== this.second_direction_name;
            }),
          };
        }
      );
    },
    "formData.third_direction_id"() {
      this.formattedInfoTracks = this.infoTracks.map((track) => {
        return {
          id: track.id
            .filter((item) => {
              return item !== this.formData.second_direction_id;
            })
            .filter((item) => {
              return item !== this.formData.third_direction_id;
            }),
          title: track.title
            .filter((item) => {
              return item !== this.second_direction_name;
            })
            .filter((item) => {
              return item !== this.third_direction_name;
            }),
        };
      });
    },
  },
  computed: {
    isDisabled() {
      let boolean = false;
      if (this.currentData) {
        boolean =
          !this.currentData.is_access_new_statement ||
          this.currentData.status === 40 ||
          this.currentData.status === 50 ||
          this.currentData.status === 10;
      }
      return boolean;
    },
    info() {
      return this.$store.state.storage.storageShiftsData;
    },
    teamsPriorities() {
      return this.teamsInfo.map((item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
    },
    currentStatements() {
      let list =
        JSON.parse(JSON.stringify(this.user.info_statements?.data)) || [];
      return list;
    },
    formattedInfo() {
      return this.info.map((item, index) => {
        let slug = "first";
        if (index === 1) {
          slug = "second";
        }
        if (index === 2) {
          slug = "third";
        }
        return {
          slug: slug,
          title: item.title,
          text: item.text,
          id: item.id,
          time_start: moment(item.beginning_at).format("DD"),
          time_end: moment(item.ended_at_format, "DD.MM.YYYY").format("DD.MM"),
          ended_bid: moment(item.ended_bid_campaign_at_format, "DD.MM.YYYY")
            .locale("ru")
            .format("DD MMMM"),
          class: this.getDisabledClass(item.type_access),
          status: this.user.info_statements?.data.find((info) => {
            return info.info_shift.id === item.id;
          })?.status,
          is_access_new_statement: item.is_access_new_statement,
          statementId: this.currentStatement,
          type_access: item.type_access,
        };
      });
    },
    infoTracks() {
      return this.info
        .map((item) => {
          return {
            shiftId: item.id,
            id: item.info_directions.data.map((track) => {
              return track.id;
            }),
            title: item.info_directions.data.map((track) => {
              return track.title;
            }),
          };
        })
        .filter((track) => {
          return track.shiftId === this.$store.state.currentShiftId;
        });
    },
    firstError() {
      return this.v$.$errors[0]?.$propertyPath;
    },
    listCourses() {
      return Object.keys(this.allParam.list_course)
        .sort((a, b) => parseInt(a) > parseInt(b))
        .map((key) => ({ key, value: this.allParam.list_course[key] }));
    },
    listSizes() {
      return this.allParam.list_clothing_size;
    },
    listRepresents() {
      return this.allParam.list_represents;
    },
    listOrganization() {
      return this.allParam.list_organization;
    },
    listPreference() {
      return this.allParam.list_preference;
    },
    listAboutForum() {
      return Object.keys(this.allParam.list_about_from)
        .sort((a, b) => parseInt(a) > parseInt(b))
        .map((key) => ({ key, value: this.allParam.list_about_from[key] }));
    },
    ...mapGetters(["userProps"]),
    ...mapState(["user"]),
  },
  methods: {
    test(id, refName) {
      this.$store.state.currentShiftId = id;
      this.currentData = this.user.info_statements?.data.find(
        (item) => item.info_shift.id === this.$store.state.currentShiftId
      );
      this.isShiftSet = false;
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
      this.dataUpdated = true;
    },
    openPopup(item, gallery) {
      this.$store.commit("setCurrentPopup", {
        name: "TeamPopup",
        isShown: true,
        props: {
          info: item,
          popupClass: "team-popup",
          gallery: gallery,
        },
      });
    },
    setShift(id, refName, isValidDate) {
      this.$store.state.currentShiftId = id;
      this.isShiftSet = true;

      const element = this.$refs[refName];
      const top = element.offsetTop;
      window.scrollTo(0, top);

      Promise.all([
        (this.currentData = this.user.info_statements?.data.find(
          (item) => item.info_shift.id === this.$store.state.currentShiftId
        )),
      ])
        .then(() => {
          if (this.currentData) {
            this.currentData.is_access_new_statement = isValidDate;
          }
          this.formData.first_direction_id =
            this.currentData?.first_direction.id;
          this.first_direction_name = this.currentData?.first_direction.title;
          this.formData.second_direction_id =
            this.currentData?.second_direction.id;
          this.second_direction_name = this.currentData?.second_direction.title;
          this.formData.third_direction_id =
            this.currentData?.third_direction.id;
          this.third_direction_name = this.currentData?.third_direction.title;
          this.formData.clothing_size = this.currentData?.clothing_size.key;
          this.formData.course_level = this.currentData?.course_level.key;
          this.formData.community_represent_id =
            this.currentData?.community_represent.key;
          this.formData.community_represent_other =
            this.currentData?.community_represent.other;
          this.formData.community_represent_name =
            this.currentData?.community_represent.name;
          this.formData.position_organization_other =
            this.currentData?.position_organization.other;
          this.formData.position_organization_id =
            this.currentData?.position_organization.key;
          this.formData.what_does_student_mean_you =
            this.currentData?.what_does_student_mean_you;
          this.formData.purpose_travel = this.currentData?.purpose_travel;
          this.formData.your_main_achievements =
            this.currentData?.your_main_achievements;
          this.formData.hear_about_forum_id =
            this.currentData?.hear_about_forum.key;
          this.formData.hear_about_forum_other =
            this.currentData?.hear_about_forum.other;
          this.formData.hear_about_forum_friend =
            this.currentData?.hear_about_forum.friend;
          this.formData.health_features = this.currentData?.health_features;
          this.formData.food_preference_id =
            this.currentData?.food_preference.key;
          this.$store.state.user.currentRequestId = this.currentData?.id;
        })
        .then(() => {
          this.formData.first_team_id = this.currentData?.first_team?.id;
          this.first_team_name = this.currentData?.first_team?.title;
          this.second_team_name = this.currentData?.second_team?.title;
          this.third_team_name = this.currentData?.third_team?.title;
          this.formData.second_team_id = this.currentData?.second_team?.id;
          this.formData.third_team_id = this.currentData?.third_team.id;
        })
        .then(() => {
          this.$nextTick(() => {
            this.formChanged = false;
            this.$store.commit("setDataSaved", true);
          });
        });

      if (!this.$store.state.storage.storageTeamsData.length) {
        createRequest(requestConfigs.GETInfoBlock, {
          queryPayload: {
            key_block: "teams",
          },
        })
          .then((result) => {
            this.$store.commit("setTeamsData", result.data);
            this.teamsInfo = this.$store.state.storage.storageTeamsData;
          })
          .then(() => {});
      } else this.teamsInfo = this.$store.state.storage.storageTeamsData;

      if (
        JSON.stringify(this.$store.state.storage.storageStatementParamsData) ===
        "{}"
      ) {
        this.isLoadingData = true;
        createRequest(requestConfigs.GETAllParams)
          .then((result) => {
            this.$store.commit("setStatementParamsData", result.data);
            this.allParam =
              this.$store.state.storage.storageStatementParamsData;
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      } else {
        this.allParam = this.$store.state.storage.storageStatementParamsData;
      }
    },
    async handleFormSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return (this.formIncorrect = true);
      this.isLoading = true;
      const MY_OBJECT = {
        shift_id: this.formData.shift_id,
        first_direction_id: this.formData.first_direction_id,
        second_direction_id: this.formData.second_direction_id,
        third_direction_id: this.formData.third_direction_id,
        course_level: this.formData.course_level,
        clothing_size: this.formData.clothing_size,
        community_represent_id: this.formData.community_represent_id,
        community_represent_other: this.formData.community_represent_other,
        community_represent_name: this.formData.community_represent_name,
        position_organization_other: this.formData.position_organization_other,
        position_organization_id: this.formData.position_organization_id,
        what_does_student_mean_you: this.formData.what_does_student_mean_you,
        purpose_travel: this.formData.purpose_travel,
        your_main_achievements: this.formData.your_main_achievements,
        hear_about_forum_id: this.formData.hear_about_forum_id,
        health_features: this.formData.health_features,
        food_preference_id: this.formData.food_preference_id,
        hear_about_forum_other: this.formData.hear_about_forum_other,
        hear_about_forum_friend: this.formData.hear_about_forum_friend,
        first_team_id: this.formData.first_team_id,
        second_team_id: this.formData.second_team_id,
        third_team_id: this.formData.third_team_id,
      };
      const payload = Object.keys(MY_OBJECT)
        .filter((f) => MY_OBJECT[f] !== undefined && MY_OBJECT[f] !== null)
        .reduce((r, i) => {
          r[i] = MY_OBJECT[i];
          return r;
        }, {});
      if (!this.currentData) {
        await createRequest(requestConfigs.POSTRequest, {
          jsonPayload: payload,
        })
          .then(() => {
            this.$store.dispatch("user/getUserInfo");
            this.dataSuccess = true;
            this.timeout = setTimeout(() => {
              this.dataSuccess = false;
            }, 5000);
            this.$router.push({ name: "LkMyRequests" });
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            this.errors = error.errors;
          });
      } else {
        await createRequest(requestConfigs.POSTRequestUpdate, {
          jsonPayload: payload,
          routerPayload: {
            id: this.$store.state.user.currentRequestId,
          },
        })
          .then(() => {
            this.$store.dispatch("user/getUserInfo");
            this.dataUpdated = true;
            this.timeout = setTimeout(() => {
              this.dataUpdated = false;
            }, 5000);
            this.$router.push({ name: "LkMyRequests" });
          })
          .finally(() => {
            this.isLoading = false;
          })
          .catch((error) => {
            this.errors = error.errors;
          });
      }
    },
    goToAnchor(refName) {
      let str = refName;
      let result = str.split(".").join("_");
      let elementPosition = document.querySelector(`.${result}`).offsetTop;
      window.scrollTo({
        top: elementPosition - 20,
        behavior: "smooth",
      });
    },
    communityRepresentIdChanged() {
      if (this.formData.community_represent_id !== 99) {
        this.formData.community_represent_other = null;
        this.formData.community_represent_name = null;
      }
      if (
        this.formData.community_represent_id !== 99 &&
        this.formData.community_represent_id === 0
      ) {
        this.formData.community_represent_name = null;
      }
    },
    positionOrganizationChanged() {
      if (this.formData.position_organization_id !== 99) {
        this.formData.position_organization_other = null;
      }
    },
    hearAboutForumChanged() {
      if (this.formData.hear_about_forum_id !== 99) {
        this.formData.hear_about_forum_other = null;
      }
      if (this.formData.hear_about_forum_id !== 77) {
        this.formData.hear_about_forum_friend = null;
      }
    },
    getDisabledClass(types) {
      let className = "";
      if (types.length === 1) {
        if (
          types[0] === "vuz" &&
          this.$store.state.user.user.info_university?.type !== "vuz"
        ) {
          className = "shifts-item--disabled";
        } else if (
          types[0] === "suz" &&
          this.$store.state.user.user.info_university?.type !== "suz"
        ) {
          className = "shifts-item--disabled";
        }
      } else if (types.length === 2) {
        className = "";
      } else className = "shifts-item--disabled";

      return className;
    },
  },
  beforeMount() {
    Promise.all([this.$store.dispatch("user/getUserInfo")]);
    this.$store.state.currentShiftId = 0;
    this.isShiftSet = false;
  },
  mounted() {
    this.$store.commit("setDataSaved", true);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.currentData) {
      if (this.dataSuccess && !this.$store.state.dataSaved) {
        next();
      } else if (!this.$store.state.dataSaved) {
        this.$store.state.nextRoute = to.name;
        Promise.all([store.dispatch("openSavedPopup")]);
      } else next();
    } else {
      if (this.dataUpdated && !this.$store.state.dataSaved) {
        next();
      } else if (!this.$store.state.dataSaved) {
        this.$store.state.nextRoute = to.name;
        Promise.all([store.dispatch("openSavedPopup")]);
      } else next();
    }
  },
};
</script>

<style lang="scss">
.lk-requests {
  &__title {
    font-size: 20px;
    font-weight: 600;
    color: var(--black);
    span {
      color: var(--gray);
    }
  }
  &__errors {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px;
    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: var(--red);
    }
  }
  &__container {
    max-width: 800px;
  }
  &__shifts {
    h2 {
      margin-bottom: 30px;
    }

    margin-bottom: 100px;
  }
  &__priorities {
    display: flex;
    gap: 40px;
    margin-bottom: 60px;
    align-items: flex-start;
    flex-wrap: wrap;
    p {
      @include text20();
      color: var(--black2);
      font-weight: 600;
      align-self: center;
    }
    .dropdown {
      flex-grow: 1;
      max-width: 300px;
    }
  }
  &__answer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
    &-head {
      display: flex;
      align-items: center;
      grid-gap: 5px;
      i {
        position: relative;
        cursor: pointer;
        &[data-tooltip]::after {
          font-size: 14px;
          width: 300px;
          left: -2em;
          top: -7em;
        }
      }
    }
    p {
      @include text20();
      color: var(--black2);
      font-weight: 600;
    }
    .dropdown {
      margin-top: 40px;
    }
  }
  &__submit {
    margin-top: 40px;
    button {
      margin: 0 auto;
    }
  }
  &__comment {
    padding: 10px 30px;
    border: 2px solid var(--black);
    border-radius: 15px;
    color: var(--btn-gray-color1);
    &-title {
      margin-bottom: 5px;
    }
    &-text {
      font-size: 14px;
      line-height: 15px;
    }
  }
  &__content-info {
    padding-top: 20px;
  }
  @include adaptive(tablet-small) {
    &__title {
      font-size: 14px;
    }
    &__priorities {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: 30px;
      .dropdown {
        max-width: unset;
        width: 100%;
      }
    }
    &__answer {
      &-head {
        i {
          &[data-tooltip]::after {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
  @include adaptive(phone) {
    &__comment {
      padding: 20px;
      font-size: 14px;
      line-height: 15px;

      &-text {
        font-size: 12px;
        line-height: 13px;
      }
    }
    &__answer {
      &-head {
        i {
          &[data-tooltip]::after {
            top: -6em;
            left: unset;
            right: -15px;
            width: 280px;
          }
        }
      }
    }
  }
}
</style>
